<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <!-- header -->
          <b-row
              align-h="between"
              align-v="center"
              class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                Usuarios
              </h3>
            </b-col>
            <b-col cols="auto">
              <b-link v-if="$route.name === 'internals'" :to="{ name: 'createInternals' }">
                <b-button v-if="currentUser.roles[0].name === 'super_admin'" variant="primary" class="mr-1 text-nowrap">
                  {{ '+ Añadir usuario' }}
                </b-button>
              </b-link>
              <b-link v-else :to="{ name: 'createUser' }">
                <b-button variant="primary" class="mr-1 text-nowrap">
                  {{ '+ Añadir usuario' }}
                </b-button>
              </b-link>
            </b-col>
          </b-row>
          <hr class="m-0" />

          <!-- filters -->
          <div class="p-1">
            <b-col cols="12" class="text-right">
              <span class="cursor-pointer" @click="visibleFilter = !visibleFilter">
                <feather-icon icon="FilterIcon" size="20" />
              </span>
            </b-col>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2">
                <b-row>
                  <b-col sm="4" v-if="($route.name !== 'internals') && currentUser.roles[0].name !== 'admin'">
                    <b-form-group label-for="filter-client">
                      <SelectClients v-model="client" />
                    </b-form-group>
                  </b-col>

                  <b-col sm="4">
                    <b-form-group label-for="filter-client">
                      <flat-pickr
                        v-model="date"
                        name="date"
                        class="form-control"
                        :placeholder="'Filtrar por registro'"
                        :config="dateConfig"
                    />
                    <button v-if="date" @click="date = ''" class="clear-button">
                      <feather-icon icon="XIcon" size="15" color="#808B96"  stroke-width="4"/>
                    </button>
                  </b-form-group>
                  </b-col>

                  <b-col sm="4">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-form-input v-model="searchTerm" :placeholder="'Nombre'" type="text"
                        class="d-inline-block w-50" />
                      <b-button class="ml-1" @click="handleSearch" variant="primary">
                        {{ $t("Buscar") }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>

          </div>
          <!-- table -->
          <b-table hover responsive :items="items" :fields="columns">
            <template #cell(fullName)="data">
              <div class="d-flex align-items-center">
                <b-avatar size="md" :src="data.item.avatar" />
                <span class="ml-1">
                  <b-link :to="{ name: 'viewUser', params: { id: data.item.id } }" class="link">
                    {{ data.item.name }}
                  </b-link>
                </span>
              </div>
            </template>
            <template #cell(created_at)="data">
              <span> {{ formatDate(data.item.created_at, 'd/m/Y') }}</span>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusVariant(data.item.active)">
                {{ statusVariantText(data.item.active) }}
              </b-badge>
            </template>
            <template #cell(roles)="data">
              <div v-if="data.item.roles">
                <span v-for="item in data.item.roles" :key="item.id" class="mr-50">
                  {{ item.display_name ? item.display_name[currentLanguage] : ''}}
                </span>
              </div>
            </template>
            <template #cell(clients)="data">
              <span v-if="data.item.clients.length == 0">
                Tecnitex
              </span>
              <span v-else>
                <div v-for="(client, index) of data.item.clients" track-by="id">
                  <b-link :to="{ name: 'viewClient', params: { id: client.id } }" class="link">
                    {{ client.name }}
                  </b-link>
                </div>
              </span>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link :to="{ name: 'viewUser', params: { id: data.item.id } }" class="d-inline-block px-50 text-dark">
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link v-if="currentUser.roles[0].name === 'super_admin'"
                  :to="{ name: 'editUser', params: { id: data.item.id } }" class="d-inline-block px-50 text-dark">
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span v-if="currentUser.roles[0].name === 'super_admin'" class="d-inline-block px-50 text-danger"
                  @click="deleteUser(data.item.id, data.item.name)">
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div v-if="totalItems > pageLength" class="d-flex align-items-center justify-content-between mb-1 px-2">
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                {{ 'Por página' }}:
              </span>
              <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
              <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="handleChangePage">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BAvatar,
  BCol, BRow, BLink, BInputGroup, BInputGroupPrepend, BFormGroup, BCollapse
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import { formatDate } from '@core/utils/filter'
import SelectClients from "@/components/clients/select/ClientsSelect.vue";

export default {
  components: {
    SelectClients,
    BAvatar,
    BBadge,
    BCard,
    BPagination,
    BFormInput,
    BFormSelect,
    BTable,
    BButton,
    BCol,
    BRow,
    BLink,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    vSelect,
    BCollapse,
    flatPickr
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '25', '50'],
      columns: [
        {
          label: 'Nombre',
          key: 'fullName',
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'Fecha de registro',
          key: 'created_at',
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'Rol',
          key: 'roles',
        },
        {
          label: 'Empresa',
          key: 'clients',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      formatDate,
      date: '',
      dateConfig: {
        mode: "range"

      },
      date_ini: '',
      date_fin: '',
      searchTerm: '',
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      client: '',
      visibleFilter: false
    }
  },
  computed: {
    ...mapGetters({
      items: 'users/getItems',
      totalItems: 'users/getTotalItems',
      currentUser: 'auth/getUser',
      currentLanguage: 'languages/getCurrentLanguage'

    }),
    statusVariant() {
      const statusColor = {
        1: 'light-success',
        0: 'light-danger',
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },
    statusVariantText() {
      const statusText = {
        1: 'Activo',
        0: 'Pendiente',
        true: 'Activo',
        false: 'Pendiente',
      }

      return status => statusText[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    await this.chargeData()
  },
  watch: {
    "$route.name"(val) {
      this.chargeData()
    },
    client() {
      this.currentPage = 1
      this.chargeData()
    },

    date() {

      let range = this.date.split(' a ')

      this.date_ini = FlatPickrToTimestamp(range[0]);
      if (range.length > 1) {
        this.date_fin = FlatPickrToTimestamp(range[1]);
      } else {
        this.date_fin = '';
      }

      this.currentPage = 1;
      this.chargeData();

    },
  },
  methods: {
    ...mapActions({
      list: 'users/getListUsers',
      delete: 'users/delete',
    }),
    chargeData() {

      this.$forceUpdate()

      var client = this.client ? this.client.id : "";
      var roles = ''

      if (this.$route.name === 'internals') {
        roles = ['super_admin', 'tec']
      } else {
        roles = ['user', 'admin', 'documental', 'tec_client']
      }

      // let date = this.date ? FlatPickrToTimestamp(this.date) : '';

      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        roles,
        client_id: client,
        date_ini: this.date_ini,
        date_fin: this.date_fin
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteUser(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
    // dateColumn(time) {
    //   return TimestampToFlatPickr(time)
    // }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.clear-button {
  position: absolute;
  top: 18%;
  right: 42px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
</style>

